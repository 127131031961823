import React from 'react';
import { arrayOf, shape } from 'prop-types';
import { BlockWrapper, Hero } from '@/components';
import { getHome, getMetadata } from '@/lib/graphcms';

const Index = ({ page: { blocks = [], hero } }) => (
  <>
    {hero && <Hero {...hero} />}
    {blocks?.map(({ id, ...rest }) => (
      <BlockWrapper key={id} {...rest} />
    ))}
  </>
);

Index.propTypes = {
  page: shape({
    blocks: arrayOf(shape({}).isRequired).isRequired,
    hero: shape({}),
  }).isRequired,
};

export async function getStaticProps({ preview = false }) {
  const page = await getHome(preview);
  const metadata = await getMetadata(preview);

  return {
    props: { preview, page, metadata },
    revalidate: 60,
  };
}

export default Index;
